/* eslint-disable import/max-dependencies */
import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardActionArea, Stack, type SxProps, type Theme } from "@mui/material";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { type RecentColleague } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { useGetQualificationDisplayLabel } from "@src/appV2/Qualifications/api/useGetQualificationDisplayLabel";
import { useIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useIsOnCallWorkplace";
import { minutesToMilliseconds, parseISO } from "date-fns";

import { getShiftWorkDurationInHours } from "../../utils/getShiftWorkDuration";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import { WorkTogetherStack } from "../../WorkWithFriends/components/WorkTogetherStack";
import { type WorkerPublicProfile } from "../../WorkWithFriends/hooks/useGetWorkerPublicProfile";
import { useRequiresBreakPolicyAcknowledgement } from "../Booking/useRequiresBreakPolicyAcknowledgement";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { BookingCardContentWrapper } from "../CardContentWrapper";
import { ShiftType } from "../constants";
import { ShiftCardPillsWrapper } from "../PillsWrapper";
import { resolveShiftType } from "../resolveShiftType";
import { BookingCardSummaryWrapper } from "../SummaryWrapper";
import { TimeSlotIndicator } from "../TimeSlotIndicator";
import { ShiftCardSummaryInfo } from "./CardSummaryInfo";
import { OpenShiftCardFooter } from "./Footer";
import { OnCallShiftCardContent } from "./OnCall/CardContent";
import { OpenShiftCardPills } from "./Pills";
import type { Offer, OpenShift, OpenShiftWorkplace } from "./types";
import { useGetShiftModal } from "./useGetShiftModal";
import { useLogOpenShiftViewed } from "./useLogOpenShiftViewed";

interface OnClickParams {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  offer?: Offer;
}

interface OpenShiftCardProps {
  shift: OpenShift;
  workers: WorkerPublicProfile[];
  workplace: OpenShiftWorkplace;
  onClick?: (params: OnClickParams) => void;
  missingDocuments: HcpRequirement[];
  showMissingDocumentsCount?: boolean;
  isInstantReview?: boolean;
  sx?: SxProps<Theme>;
  friendsMap?: Map<string, WorkerPublicProfile>;
  recentColleaguesMap?: Map<string, RecentColleague>;
}

export function OpenShiftCard(props: OpenShiftCardProps) {
  const {
    shift,
    workplace,
    onClick,
    missingDocuments,
    showMissingDocumentsCount,
    isInstantReview,
    workers,
    sx,
    friendsMap,
    recentColleaguesMap,
  } = props;
  const { timeSlot, start, end, qualification, window, priorityTill, isUrgent } = shift.attributes;

  const { data: qualificationText, isLoading: isLoadingQualification } =
    useGetQualificationDisplayLabel(qualification);

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };
  const shiftType = resolveShiftType({ isUrgent, window, priorityTill });

  // we're gonna rerender this component every minute to check if the priority shift is still valid
  useCurrentDate(minutesToMilliseconds(1));

  // Get auto-refreshed offer and pre-fetch shift details for modals.
  const {
    data: { offer },
    offerIsError,
    offerIsLoading,
    isError,
  } = useGetShiftModal(shift.id);

  const isOnCallShift = useIsOnCallWorkplace(workplace.id);

  const { ref, inView } = useLogOpenShiftViewed({
    start,
    end,
    workplaceId: shift.relationships.workplace.data.id,
    shiftId: shift.id,
    hourlyPay: offer ? offer.attributes.pay.amountInMinorUnits / 100 : 0,
    qualification,
    shiftType,
    priorityTill,
    window,
    offerId: offer ? offer.id : undefined,
    isInstantReview,
    missingDocuments: missingDocuments.map((document) => document.reqId),
  });

  // Pre-fetch break policy acknowledgement for the shift,
  // so that it's ready when the user clicks the shift card.
  // No need to fetch if there's no offer, as it's not bookable.
  useRequiresBreakPolicyAcknowledgement({
    workplaceId: workplace.id,
    enabled: isDefined(offer) && inView,
  });

  const { formattedDistance } = useDistanceToWorkplace({
    workplaceGeoLocation: workplace.attributes.location,
  });

  const offerAmount = isDefined(offer) ? offer.attributes.pay.amountInMinorUnits / 100 : undefined;

  const durationInHoursWithBreak = getShiftWorkDurationInHours(
    { start, end },
    workplace.attributes.requiresLunchBreak ?? false
  );

  const disabled = offerIsError || isError;

  const showUrgentPill = shiftType === ShiftType.URGENT;

  const showPriorityPill =
    shiftType === ShiftType.PRIORITY || shiftType === ShiftType.PRIORITY_LEGACY;

  const showPriorityPlusPill = shiftType === ShiftType.PRIORITY_PLUS;

  const pillsCount = [
    showUrgentPill,
    showPriorityPill,
    showPriorityPlusPill,
    showMissingDocumentsCount,
  ].filter(Boolean).length;

  return (
    <ShiftCardWrapper
      sx={mergeSxProps({ display: "flex", opacity: disabled ? 0.5 : 1 }, sx)}
      data-testid={`shift-card-${shift.id}`}
    >
      <CardActionArea
        ref={ref}
        sx={{ flexGrow: 1 }}
        disabled={disabled}
        onClick={() => onClick?.({ shift, workplace, offer })}
      >
        {isOnCallShift ? (
          <OnCallShiftCardContent
            timeSlot={timeSlot}
            startDate={parseISO(start)}
            endDate={parseISO(end)}
            isLoading={offerIsLoading}
            offerUnavailable={offerIsError}
            hourlyPay={isDefined(offerAmount) ? formatDollarsAsUsd(offerAmount) : undefined}
            qualificationText={qualificationText}
            workplaceTimezone={workplace.attributes.location.timezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        ) : (
          <BookingCardContentWrapper>
            <BookingCardSummaryWrapper>
              <TimeSlotIndicator timeSlot={timeSlot} />
              <ShiftCardSummaryInfo
                sx={{ flex: 1 }}
                dateRange={dateRange}
                timezone={workplace.attributes.location.timezone}
                deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
                durationInHours={durationInHoursWithBreak}
                workplaceName={workplace.attributes.name}
                formattedDistance={formattedDistance}
              >
                {pillsCount > 0 && (
                  <Stack flexDirection="row" alignItems="center">
                    <ShiftCardPillsWrapper>
                      <OpenShiftCardPills
                        shiftType={shiftType}
                        missingDocumentsCount={missingDocuments.length}
                        showMissingDocumentsCount={showMissingDocumentsCount}
                      />
                    </ShiftCardPillsWrapper>
                    <WorkTogetherStack
                      workers={workers}
                      friendsMap={friendsMap}
                      recentColleaguesMap={recentColleaguesMap}
                    />
                  </Stack>
                )}
              </ShiftCardSummaryInfo>

              {pillsCount <= 0 && (
                <Stack>
                  <WorkTogetherStack
                    workers={workers}
                    friendsMap={friendsMap}
                    recentColleaguesMap={recentColleaguesMap}
                  />
                </Stack>
              )}
            </BookingCardSummaryWrapper>

            <OpenShiftCardFooter
              offerPay={offerAmount}
              finalPay={offerAmount}
              originalAmount={
                isDefined(offerAmount) ? durationInHoursWithBreak * offerAmount : undefined
              }
              offerUnavailable={offerIsError}
              qualificationText={qualificationText}
              isLoadingOffer={offerIsLoading}
              isLoadingQualification={isLoadingQualification}
            />
          </BookingCardContentWrapper>
        )}
      </CardActionArea>
    </ShiftCardWrapper>
  );
}
/* eslint-enable import/max-dependencies */
